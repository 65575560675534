<template lang="pug">
.game_log
  v-container.table_container
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 比賽紀錄
    v-row.table_row
      v-col#sticky-nav.table_col.table
        table
          thead.top_head
            top-head(category="GameLog")
          thead.bottom_head
            bottom-head(category="GameLog")
          tbody
            tr(v-for="(game, index) in playerGameList", :key="index")
              sticky-column.bdl.bdr2.link(
                style="width: 100px; min-width: 100px",
                :title="formatDate(game.date)",
                :offset="0",
                @click.native="gameClick(game)"
              )
              sticky-column.bdr2(
                style="width: 80px; min-width: 80px",
                :title="game.opp_team_name",
                :offset="100"
              )
              td {{ game.is_home ? '主' : '客' }}
              td(:class="[game.is_win ? 'text_secondary' : 'text_light_gray']") {{ game.is_win ? '勝' : '負' }}
              td {{ getScore(game) }}
              td.bdr {{ getMinutes(game.box.secs) }}
              td.hover(
                @click="dataClick(game, 'pts', '得分')",
                :class="[game.box.pts > 0 ? 'link_deep' : 'gray_out']"
              ) {{ game.box.pts }}
              td(
                @click="dataClick(game, 'reb', '籃板')",
                :class="[game.box.reb > 0 ? 'link_deep' : 'gray_out']"
              ) {{ game.box.reb }}
              td.hover(
                @click="dataClick(game, 'ast', '助攻')",
                :class="[game.box.ast > 0 ? 'link_deep' : 'gray_out']"
              ) {{ game.box.ast }}
              td.hover(
                @click="dataClick(game, 'stl', '抄截')",
                :class="[game.box.stl > 0 ? 'link_deep' : 'gray_out']"
              ) {{ game.box.stl }}
              td.hover.bdr(
                @click="dataClick(game, 'blk', '阻攻')",
                :class="[game.box.blk > 0 ? 'link_deep' : 'gray_out']"
              ) {{ game.box.blk }}
              td.hover(
                @click="dataClick(game, 'fgm', '投籃命中')",
                :class="[game.box.fgm > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.fgm }}
              td.hover(
                @click="dataClick(game, 'fga', '投籃出手')",
                :class="[game.box.fga > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.fga }}
              td.bdr {{ (game.box.fg_percent * 100).toFixed(1) }}%

              td.hover(
                @click="dataClick(game, 'two_pt_fgm', '兩分命中')",
                :class="[game.box.two_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.two_pt_fgm }}
              td.hover(
                @click="dataClick(game, 'two_pt_fga', '兩分出手')",
                :class="[game.box.two_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.two_pt_fga }}
              td.bdr {{ (game.box.two_pt_fg_percent * 100).toFixed(1) }}%

              td.hover(
                @click="dataClick(game, 'three_pt_fgm', '三分命中')",
                :class="[game.box.three_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.three_pt_fgm }}
              td.hover(
                @click="dataClick(game, 'three_pt_fga', '三分出手')",
                :class="[game.box.three_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.three_pt_fga }}
              td.bdr {{ (game.box.three_pt_fg_percent * 100).toFixed(1) }}%
              td.hover(
                @click="dataClick(game, 'ft_fgm', '罰球命中')",
                :class="[game.box.ftm > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.ftm }}
              td.hover(
                @click="dataClick(game, 'ft_fga', '罰球出手')",
                :class="[game.box.fta > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.fta }}
              td.bdr {{ (game.box.ft_percent * 100).toFixed(1) }}%
              td.hover(
                @click="dataClick(game, 'off_reb', '進攻籃板')",
                :class="[game.box.off_reb > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.off_reb }}
              td.hover.bdr(
                @click="dataClick(game, 'def_reb', '防守籃板')",
                :class="[game.box.def_reb > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.def_reb }}
              td.hover(
                @click="dataClick(game, 'tov', '失誤')",
                :class="[game.box.tov > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.tov }}
              td.hover(
                @click="dataClick(game, 'pf', '犯規')",
                :class="[game.box.pf > 0 ? 'link' : 'gray_out']"
              ) {{ game.box.pf }}
              td.hover(:class="[game.box.plus_minus > 0 ? '' : 'gray_out']") {{ game.box.plus_minus }}
              td.hover(:class="[game.box.eff > 0 ? '' : 'gray_out']") {{ game.box.eff }}
              td.hover(:class="[game.box.at_ratio > 0 ? '' : 'gray_out']") {{ game.box.at_ratio }}

    v-dialog.position-relative(
      v-model="isDialogShow",
      style="background: white; height: 100%; width: 810px"
    )
      .close_btn_container
        v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
      v-container.position-relative.content_container(ref="content")
        v-row(justify="center")
          .uvideo
            youtube(
              ref="youtube",
              @ready="onYTReady",
              @ended="onYTEnd",
              resize,
              fitParent,
              nocookie,
              style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
            )

            .yt_cover(v-if="coverYT")
              v-btn.play_all.cover(
                @click="playAllClick",
                rounded="",
                color="#487aa4",
                dark,
                v-ripple="false"
              )
                v-icon(left="")
                  | mdi-play
                | 播放全部
        v-row(justify="space-between")
          .reference
            //- span.d-block 影片出處：
            //-   a.text_primary(
            //-     href="https://www.youtube.com/c/ssutv/videos",
            //-     target="_blank"
            //-   ) https://www.youtube.com/c/ssutv/videos
            //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
          v-btn.play_all(
            @click="playAllClick",
            rounded="",
            color="#487aa4",
            dark,
            v-ripple="false"
          )
            v-icon(left="")
              | mdi-play
            | 播放全部
        v-row
          span.player_name_team {{ dialogName }} | {{ dialogTeam }}
          .play_table
            .play_head
              .head.play_play
              .head.play_time 時間
              .head.play_player 球員
              .head.play_team 隊伍
              .head.play_event 事件
              .head.play_feedback 問題回報
            .play_content
              .play_item(
                :class="{ selected: currentPlayIndex == index }",
                v-for="(play, index) in playList",
                :key="index"
              )
                .content.play_play
                  v-icon.play(x-small, color="white") mdi-play
                .content.play_time {{ play.video_start_at }}
                .content.play_player {{ dialogName }}
                .content.play_team {{ dialogTeam }}
                .content.play_event {{ dialogEvent }}
                .content.play_feedback
                  v-icon(small) mdi-alert-octagon
                .play_btn(@click="onPlayItemClick(play, index)")
                .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
import BottomHead from "@/components/common/Box/BottomHead";
import TopHead from "@/components/common/Box/TopHead";
import { getGameList } from "@/api/game";
import { getPlayList } from "@/api/play";
import { getPlayerSeasonData } from "@/api/player";
import moment from "moment";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";

export default {
  name: "LeaguePlayerGameLog",
  components: {
    StickyColumn,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    BottomHead,
    TopHead,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      playerID: this.$route.params.id,
      seasonPlayerId: 0,
      seasonPlayerName: null,
      seasonPlayerTeamName: null,
      playerGameList: [],

      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  watch: {
    "$route.params.season"() {
      this.season = this.$route.params.season;
      this.playerGameList = [];
      this.getSeasonPlayerInfo();
    },
    season() {
      this.playerGameList = [];
      this.getSeasonPlayerInfo();
    },
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getSeasonPlayerInfo();
    },
    async getSeasonPlayerInfo() {
      let temp = {
        season_id: this.season,
        part: "info",
        player_id: this.playerID,
      };

      await getPlayerSeasonData(temp).then((response) => {
        let playerData =
          response.data.summary[response.data.summary.length - 1];
        this.seasonPlayerId = playerData.info.season_player_id;
        this.seasonPlayerName = playerData.info.name;
        this.seasonPlayerTeamName = playerData.info.team_name;
        this.getPlayerGameList();
      });
    },
    async getPlayerGameList() {
      let temp = {
        show_all_page: true,
        season_id: this.season,
        group_by: "PLAYER",
        season_player_id: this.seasonPlayerId,
      };
      await getGameList(temp).then((response) => {
        this.playerGameList = response.data;
      });
    },
    getScore(playerGameStat) {
      return playerGameStat.home_team_pts + ":" + playerGameStat.away_team_pts;
    },
    formatDate(date) {
      return moment(date).format("YYYY/MM/DD");
    },
    dataClick(game, stat, event) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = this.seasonPlayerName;
      this.dialogTeam = this.seasonPlayerTeamName;
      this.dialogEvent = event;
      this.getPlayList(game.id, stat);
    },
    getPlayList(gameId, stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        season_player_id: this.seasonPlayerId,
        group_by: "PLAYER",
        game_id: gameId,
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].game__youtube_ids__0,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.ft_attempt > 0
          ? moment.duration(play.video_end_at).asSeconds() - 14
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_record_duration,
        endSeconds: moment.duration(play.video_end_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    gameClick(game) {
      this.$router.push({
        name: "LeagueGame",
        params: {
          id: game.id,
        },
      });
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";
@import "@/assets/style/common/dialog_play_video";
@import "@/assets/style/league/player/game_log";
</style>
